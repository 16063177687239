import { useState, useEffect, useRef, useCallback } from "react";

const PRIMARY_URL = "wss://2140data.io";
// const PRIMARY_URL = "wss://2140data.com";
const FALLBACK_URL = "wss://2140data.org";
const NO_DATA_TIMEOUT = 15000; // 15 seconds

export const useTimechainWebSocket = () => {
  const [bitcoinPrice, setBitcoinPrice] = useState(null);
  const [isDataFlowing, setIsDataFlowing] = useState(false);
  const wsRef = useRef(null);
  const dataTimeoutRef = useRef(null);
  const currentUrlRef = useRef(PRIMARY_URL);

  const resetDataTimeout = useCallback(() => {
    if (dataTimeoutRef.current) {
      clearTimeout(dataTimeoutRef.current);
    }
    dataTimeoutRef.current = setTimeout(() => {
      setIsDataFlowing(false);
      console.log(`No data received for ${NO_DATA_TIMEOUT / 1000} seconds, switching to ${currentUrlRef.current === PRIMARY_URL ? 'fallback' : 'primary'}`);
      currentUrlRef.current = currentUrlRef.current === PRIMARY_URL ? FALLBACK_URL : PRIMARY_URL;
    }, NO_DATA_TIMEOUT);
  }, []);

  const connectWebSocket = useCallback((url) => {
    console.log(`Attempting to connect to ${url}`);

    if (wsRef.current) {
      wsRef.current.onclose = null;
      wsRef.current.close();
    }

    wsRef.current = new WebSocket(url);

    wsRef.current.onopen = () => {
      console.log(`WebSocket connected to ${url}`);
      setIsDataFlowing(false);
      resetDataTimeout();
    };

    wsRef.current.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.weightedPrice) {
          const price = parseFloat(data.weightedPrice).toFixed(2);
          setBitcoinPrice(price);
          setIsDataFlowing(true);
          resetDataTimeout();
        } else {
          console.log('Received data does not contain weightedPrice:', data);
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    wsRef.current.onerror = (error) => {
      console.error(`WebSocket error for ${url}:`, error);
    };

    wsRef.current.onclose = (event) => {
      console.log(`WebSocket closed for ${url}. Code: ${event.code}, Reason: ${event.reason}`);
      setIsDataFlowing(false);
    };
  }, [resetDataTimeout]);

  useEffect(() => {
    connectWebSocket(PRIMARY_URL);

    const intervalId = setInterval(() => {
      if (!isDataFlowing) {
        connectWebSocket(currentUrlRef.current);
      }
    }, 5000);

    return () => {
      console.log("Cleaning up WebSocket connections");
      clearInterval(intervalId);
      if (wsRef.current) {
        wsRef.current.onclose = null;
        wsRef.current.close();
      }
      if (dataTimeoutRef.current) clearTimeout(dataTimeoutRef.current);
    };
  }, [connectWebSocket, isDataFlowing]);

  return { bitcoinPrice, isConnected: isDataFlowing };
};