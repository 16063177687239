// src/components/games/utils/badgeDrawing.js
export const drawRays = (ctx, width, height) => {
    const centerX = width / 2;
    const centerY = height / 2;
    const numRays = 12;
    const rayLength = Math.sqrt(width * width + height * height) / 2;
  
    ctx.save();
    
    for (let i = 0; i < numRays; i++) {
      const angle = (i / numRays) * Math.PI * 2;
      const endX = centerX + Math.cos(angle) * rayLength;
      const endY = centerY + Math.sin(angle) * rayLength;
  
      const gradient = ctx.createLinearGradient(centerX, centerY, endX, endY);
      gradient.addColorStop(0, 'rgba(255, 255, 255, 0)');
      gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.05)');
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0.1)');
  
      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.lineTo(
        centerX + Math.cos(angle - 0.1) * rayLength,
        centerY + Math.sin(angle - 0.1) * rayLength
      );
      ctx.lineTo(
        centerX + Math.cos(angle + 0.1) * rayLength,
        centerY + Math.sin(angle + 0.1) * rayLength
      );
      ctx.closePath();
  
      ctx.fillStyle = gradient;
      ctx.fill();
    }
  
    ctx.restore();
  };
  
  export const drawTextWithShadow = (ctx, text, x, y, fontSize, fontWeight = 'normal') => {
    ctx.save();
    ctx.font = `${fontWeight} ${fontSize}px Ubuntu`;
    ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
    ctx.shadowBlur = 4;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    ctx.fillText(text, x, y);
    ctx.restore();
  };
  
  export const drawBadge = (ctx, config, width, height) => {
    ctx.clearRect(0, 0, width, height);
  
    ctx.fillStyle = config.backgroundColor;
    ctx.beginPath();
    ctx.roundRect(0, 0, width, height, 20);
    ctx.fill();
  
    drawRays(ctx, width, height);
  
    ctx.save();
    ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
    ctx.shadowBlur = 4;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    ctx.fillStyle = '#FFFFFF';
    ctx.font = `${height * 0.27}px Arial`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(config.emoji, width / 2, height * 0.3);
    ctx.restore();
  
    ctx.fillStyle = '#FFFFFF';
    ctx.textAlign = 'center';
    drawTextWithShadow(ctx, config.title, width / 2, height * 0.55, height * 0.11, 'bold');
    drawTextWithShadow(ctx, config.subtitle, width / 2, height * 0.65, height * 0.06, 'bold');
  
    ctx.textAlign = 'left';
    ctx.textBaseline = 'alphabetic';
    
    const bitcoinSymbol = '₿';
    const timechainText = 'Timechain';
    const statsText = 'Stats';
    
    ctx.font = `bold ${height * 0.065}px Ubuntu`;
    const bitcoinWidth = ctx.measureText(bitcoinSymbol).width;
    
    ctx.font = `bold ${height * 0.058}px Ubuntu`;
    const timechainWidth = ctx.measureText(timechainText).width;
    
    ctx.font = `${height * 0.058}px Ubuntu`;
    const statsWidth = ctx.measureText(statsText).width;
    
    const spacing = width * 0.016;
    const totalWidth = bitcoinWidth + timechainWidth + statsWidth + spacing * 2;
    const startX = (width - totalWidth) / 2;
    const bottomY = height - height * 0.058;
  
    drawTextWithShadow(ctx, bitcoinSymbol, startX, bottomY, height * 0.065, 'bold');
    drawTextWithShadow(ctx, timechainText, startX + bitcoinWidth + spacing, bottomY, height * 0.058, 'bold');
    drawTextWithShadow(ctx, statsText, startX + bitcoinWidth + timechainWidth + spacing * 2, bottomY, height * 0.058);
  };