// src/components/games/config/badgeConfigs.js
export const BADGE_CONFIGS = {
    triviaNightLoser: {
      backgroundColor: '#E53E3E',
      emoji: '📚🤔💡',
      title: 'Keep Learning!',
      subtitle: 'Try again!',
      buttonColor: 'red.500',
      buttonHoverColor: 'red.600',
      shadowColor: 'rgba(255,0,0,0.3)',
      downloadFileName: 'trivia_rekt_badge.png',
      shareText: 'I got beat at Trivia Night on TimechainStats.com! 📚🤔💡'
    },
    triviaNightAverage: {
        backgroundColor: '#FFA500',
        emoji: '📚🧠💡',
        title: 'Good Effort!',
        subtitle: 'Keep improving!',
        buttonColor: 'orange.500',
        buttonHoverColor: 'orange.600',
        shadowColor: 'rgba(255,165,0,0.3)',
        downloadFileName: 'trivia_average_badge.png',
        shareText: 'I held my own at Trivia Night on TimechainStats.com! 📚🧠💡'
      },
    triviaNightWinner: {
      backgroundColor: '#4CAF50',
      emoji: '🏆📚🧠',
      title: 'Great Job!',
      subtitle: 'Play again!',
      buttonColor: 'green.500',
      buttonHoverColor: 'green.600',
      shadowColor: 'rgba(76, 175, 80, 0.3)',
      downloadFileName: 'trivia_master_badge.png',
      shareText: 'I mastered Bitcoin trivia on TimechainStats.com! 🏆📚🧠'
    },
    predictionWinner: {
      backgroundColor: '#4CAF50',
      emoji: '🏆💰🚀',
      title: 'Winner, Winner!',
      subtitle: 'You won a prediction!',
      buttonColor: 'green.500',
      buttonHoverColor: 'green.600',
      shadowColor: 'rgba(76, 175, 80, 0.3)',
      downloadFileName: 'prediction_master_badge.png',
      shareText: 'I won a prediction in the Get Rekt Simulator on TimechainStats.com! 🏆💰🚀'
    },
    getRekt: {
      backgroundColor: '#E53E3E',
      emoji: '🐷💔💸',
      title: 'You got REKT!',
      subtitle: '0 Credits Remaining',
      buttonColor: 'red.500',
      buttonHoverColor: 'red.600',
      shadowColor: 'rgba(255,0,0,0.3)',
      downloadFileName: 'rekt_badge.png',
      shareText: 'I got REKT in the Get Rekt Simulator on TimechainStats.com! 🐷💔💸'
    },
    octopusVibes: {
      backgroundColor: '#6A0DAD',
      emoji: '🐙💰🌟',
      title: 'Octopus Vibes!',
      subtitle: '100,000 Credits Milestone',
      buttonColor: 'purple.500',
      buttonHoverColor: 'purple.600',
      shadowColor: 'rgba(106, 13, 173, 0.3)',
      downloadFileName: 'octopus_vibes_badge.png',
      shareText: 'I reached 100,000 credits in the Get Rekt Simulator on TimechainStats.com! 🐙💰🌟'
    },
    // Miner Tycoon Badges
  minerTycoonFirstBlock: {
    backgroundColor: '#4CAF50',
    emoji: '⛏️🎉',
    title: 'First Block Mined!',
    subtitle: 'You\'re on your way!',
    buttonColor: 'green.500',
    buttonHoverColor: 'green.600',
    shadowColor: 'rgba(76, 175, 80, 0.3)',
    downloadFileName: 'first_block_badge.png',
    shareText: 'I mined my first block in Miner Tycoon on TimechainStats.com! ⛏️🎉'
  },
  minerTycoonAsicMiner: {
    backgroundColor: '#FFA500',
    emoji: '🖥️💨',
    title: 'ASIC Power!',
    subtitle: 'Upgraded to ASIC',
    buttonColor: 'orange.500',
    buttonHoverColor: 'orange.600',
    shadowColor: 'rgba(255, 165, 0, 0.3)',
    downloadFileName: 'asic_miner_badge.png',
    shareText: 'I upgraded to an ASIC miner in Miner Tycoon on TimechainStats.com! 🖥️💨'
  },
  minerTycoonMiningFarm: {
    backgroundColor: '#1E90FF',
    emoji: '🏭🔌',
    title: 'Farm Established!',
    subtitle: 'Built a Mining Farm',
    buttonColor: 'blue.500',
    buttonHoverColor: 'blue.600',
    shadowColor: 'rgba(30, 144, 255, 0.3)',
    downloadFileName: 'mining_farm_badge.png',
    shareText: 'I established a mining farm in Miner Tycoon on TimechainStats.com! 🏭🔌'
  },
  minerTycoonBitcoinMillionaire: {
    backgroundColor: '#FFD700',
    emoji: '💰🤑',
    title: 'Bitcoin Millionaire!',
    subtitle: '1,000,000 Satoshis',
    buttonColor: 'yellow.500',
    buttonHoverColor: 'yellow.600',
    shadowColor: 'rgba(255, 215, 0, 0.3)',
    downloadFileName: 'bitcoin_millionaire_badge.png',
    shareText: 'I became a Bitcoin millionaire in Miner Tycoon on TimechainStats.com! 💰🤑'
  }
  };